
:root{
  --primary-color: #6c5dd3;
}
.pry{
  color: var(--primary-color) !important;
}
.bg_pry{
  background-color: var(--primary-color) !important;
}
.container_bg{
  background-color: #f5f8fa !important;
}
.vh90{
  min-height: 90vh;
}
.bg_white{
  background-color: #6c5dd32b !important;
}
.white{
  color: #fff;
}
.bs{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}


.container {
    max-width: 100%;
}
input[type="checkbox"]{
  width: 20px; /*Desired width*/
  height: 20px; /*Desired height*/
}
.img_sec img {
  max-width: 100%;
  height: 230px;
  width: 356px;
  object-fit: cover;
  object-position: center;
  padding: 0px;
}
.box_sec{
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


/* .search_container{
  position: fixed;
  top: 0;
  width: 100%;
} 
main{
  margin-top: 220px;
}*/

.output_container ul li{
  list-style: none;
}


.output_container .search_container form button[type="submit"]{ 
  width: 43px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0;
  background: #fff;
  /* background-color: #ffffff2b !important ; */
}
.output_container .search_container form button[type="submit"] svg{ 
  width: 21px !important;
  height: 15px;
  
}
.output_container .search_container form button[type="reset"] { 
  display: none;
}
.output_container .left_side form{
  display: flex;
  justify-content: center;

}

 .output_container .left_side form input{ 
  width: 90%;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 0;
  outline: none;
}
.output_container .left_side form button[type="submit"]{ 
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0;
  outline: none;
  background-color: #6c5dd32b ;
}
.output_container .left_side form button[type="reset"] { 
  display: none;
}
.output_container .left_side form button[type="submit"] svg{ 
    width: 21px !important;
    height: 15px;
}
.output_container .left_side ul{
  padding: 0;
  padding-top: 10px;
  width: 100%;
}
.output_container .left_side ul li{
  padding: 5px 0;
  width: 100%;
}
.output_container .left_side  .ais-RefinementList-label{
  display: flex;
  align-items: center;
}

.output_container .left_side .ais-RefinementList-labelText{
  padding-left: 4px;
}
.output_container .left_side .ais-RefinementList-count{
  background-color: var(--primary-color);
  color: #fff;
  font-size: 13px;
  border-radius: 7px;
  padding: 0px 3px;
  margin-left: 2px;
}
.output_container .left_side .ais-RefinementList-noResults{
  text-align: center;
  padding: 10px 0;
  margin-top: 10px;
  background: aliceblue;
  border-radius: 10px;
}

.output_container .right_side .ais-Pagination-list{
  display: flex;
  justify-content: center;
}

.output_container .right_side .ais-Pagination-list li{
  width: 40px;
  padding: 10px 10px;
  margin: 0 2px;
  background-color: #6c5dd3;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.output_container .right_side .ais-Pagination-list li :is(a,span) {
  text-decoration: none;
  color: #fff;
}

/*--------------------------------------------------
    BELOW THE CODE IS SET HEIGHT EQUAL COLUMN
--------------------------------------------------*/

.row.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.equal-cols:before,
.row.equal-cols:after {
  display: block;
}

.row.equal-cols > [class*='col-'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
flex-direction: column;
}

.row.equal-cols > [class*='col-'] > * {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; 
}

