.sbr {
    height: 100%;
    width: 27%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #e3dff7;
    overflow: hidden;
    transition: 0.5s;
    /* transform: translate(0); */
    z-index: 9999;
    /* position: relative; */
}
/* .sbr .panel-content{
    background-color: aqua;
} */