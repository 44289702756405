#sort_by_ .dropdown-toggle{
    background-color: var(--white-color);
    border: unset;
}
#sort_by_ .dropdown-toggle::after{
    content: unset;
}
#sort_by_ .dropdown-toggle span{
    font-weight: 600;
    color: var(--heading-color);
}
#sort_by_ .dropdown-toggle span.sort{
    color: var(--text-color);
}
#sort_by_ .dropdown-toggle svg{
    color: var(--text-color);
}

#sort_by_ .dropdown-item {
    padding: 4px 0px 6px 10px;
}
#sort_by_ .dropdown-item.active, #sort_by_ .dropdown-item:active {
    color: var(--heading-color);
    background-color: rgb(108 93 211 / 10%);
    border-radius: 6px;
}
#sort_by_ .dropdown-item:hover {
    color: var(--heading-color);
    background-color: rgb(108 93 211 / 10%);
    /* width: 96%;
    margin: 0 auto; */
    border-radius: 6px;
}
#sort_by_ .dropdown-item:hover{
    color: var(--heading-color) !important;
}
#sort_by_ .dropdown-menu.show {
    display: block;
    /* min-width: 195px !important; */
    padding: 8px 5px;
    border: unset;
}
#sort_by_ .dropdown-menu.show a{
    color: var(--text-color);
}
#sort_by_ .dropdown-menu a img{
    margin-bottom: 2px;
}