#header{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--white-color);
    z-index: 9999;
    min-height: 10vh !important;
}
#header .header .search_box_container form{
    width: 50%;
    margin: 0 auto;
}
#header .header .search_box_container form input{
    border: 0;
    border-radius: 50px;
    padding: 8px 12px;
    box-shadow: var(--bs-1);
}
#header .header #search__ form .search_box{
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50px;
    right: 7px;
}
#header .header #search__ form .search_box svg{
    font-size: 20px;
    color: var(--white-color);
}
#header .header #search__ form input:focus-visible {
    outline: none;
}
#header .header #search__ form input::placeholder {
    color: var(--heading-color);
    font-size: 15px;
    font-weight: 600;
}
#header .header .checkout_icon svg{
    font-size: 26px;
    color: var(--text-color);
}   