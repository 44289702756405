:root{
    --primary-color: #6c5dd3;
    --white-color: #ffffff;
    --text-color: #808191;
    --heading-color: #11142d;
    --slider-bg: #dee4ec;

    --bg-primary: rgb(108, 93, 211);

    --bs-1: 0 0.225rem 0.5rem rgb(0 0 0 / 15%);
}
.bs_1{
    box-shadow: var(--bs-1);
}
.text_color{
    color: var(--text-color);
}
.heading_color{
    color: var(--heading-color);
}

.pry_btn{
    background-color: var(--primary-color);
    color: var(--white-color);
}
.sec_btn{
    background-color: rgb(108 93 211 / 10%);
    color: var(--text-color);
}
