.home_left_side{
    /* height: calc(100vh - 130px) !important; */
    width: 23%;
    position: fixed;
    top: 90px;
    flex: 0 0 auto;
    /* overflow-y: scroll; */
    border-radius: 12px;    
    padding: 2.5px;
    background-color: var(--white-color);
    box-shadow: var(--bs-1);
}
.home_left_side .os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
    top: 0;
    bottom: 0;
    right: -2px;
}
.home_left_side .accordion-button{
    background: transparent;
    font-size: 17px;
    font-weight: 500;
    color: var(--text-color);
}
.home_left_side .accordion-item{
    border: none;
}
.home_left_side .accordion-button:focus{
    border-color: transparent !important;
    box-shadow: unset !important;
}
.home_left_side .accordion-button:not(.collapsed){
    border-color: transparent !important;
    box-shadow: unset !important;
}
.home_left_side .divided{
    background: #e2dcdc;
    height: 1px;
    width: 95%;
    margin: 0 auto;
}
.home_left_side .os-host, .os-host-textarea{
    height: calc(100vh - 130px) !important;
}
.home_left_side .os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: rgb(128 129 145 / 20%);
}

.home_left_side .clear_filter_container{
    position: sticky;
    bottom: 0;
    background: var(--white-color);
    padding: 10px 0;
    z-index: 9;
}
.home_left_side .clear_filter{
    background-color: rgb(108 93 211 / 10%);
    width: 90%;
    margin: 0 auto;
    border-radius: 40px;
}
.home_left_side .clear_filter button{
    background-color: rgb(108 93 211 / 10%);
    width: 100%;
    margin: 0 auto;
    padding: 10px 10px;
    outline: unset;
    border: 0;
    border-radius: 36px;
    font-size: 18px;
    font-weight: 600;
    color: var(--text-color);
    transition: 0.3s;
}
.home_left_side .clear_filter button:hover{
    color: #555;
    
}
.home_left_side .clear_filter .ais-ClearRefinements{
    border-radius: 40px;
}
.home_left_side .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon);
}


