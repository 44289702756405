#hit__ .content_section .title_sec h2{
    font-size: 21px;
    color: var(--heading-color);
}
#hit__ .content_section .distance__ svg{
    margin-bottom: 2px;
    margin-right: 4px;
}
#hit__ .content_section .distance__ p{
    font-size: 15px;
    font-size: 600;
    color: var(--text-color);
}
#hit__ .content_section .car_details .card__{
    border: 2px solid #eee;
    padding: 4px 10px 4px;
    margin: 10px 10px 0px 0px;
    border-radius: 9px;
}
#hit__ .content_section .car_details .card__{
    color: var(--text-color);
}
#hit__ .content_section .car_details .card__:hover img{
    transform: scale(1.1);
    transition: 0.5s;
}
#hit__ .content_section .bottom_section .starting_price h5{
    font-size: 22px;
}
#hit__ .content_section .bottom_section .button_section button{
    margin-left: 10px;
    padding: 6px 18px;
    border-radius: 50px;
    border: 0;
    font-weight: 500;
}
#hit__ .content_section .bottom_section .button_section button svg{
    margin-right: 8px;
}

#hit .img_sec{
    border-radius: 10px;
}

#hit__ .owl-carousel{
    position: relative;
}
#hit__ .owl-carousel .owl-nav{
    display: none;
}
#hit__ .owl-carousel .owl-dots{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
#hit__ .owl-carousel .owl-item img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
#hit__ .owl-theme .owl-dots .owl-dot span{
    /* background-color: rgb(108 93 211 / 10%); */
    background-color: #eeeff6;
}
#hit__ .owl-theme .owl-dots .owl-dot.active span{
    /* background-color: rgb(108 93 211 / 10%); */
    background-color: var(--primary-color);
}