#refinement_list_container #refinement_list__ .refinement_list__{
    position: relative;
    border: 3px solid rgb(108 93 211 / 10%); 
    transition: 0.3s;
}
#refinement_list_container #refinement_list__ .refinement_list__:hover{
    border: 3px solid rgb(108 93 211 / 40%);
    color: var(--white-color);
}
#refinement_list_container #refinement_list__ .refinement_list__:hover .count_section{
    background-color: rgb(108 93 211 / 70%);
    color: var(--white-color);
}

.select_1{
    border: 3px solid rgb(108 93 211 / 40%) !important;
    color: var(--white-color) !important;
}
.select_2{
    background-color: rgb(108 93 211 / 70%) !important;
    color: var(--white-color) !important;
}

#refinement_list_container #refinement_list__ .count_section{
    position: absolute;
    top: -10px;
    right: -8px;
    background-color: rgb(108 93 211 / 10%);
    color: var(--text-color);
    padding: 2px 6px;
    font-size: 13px;
    border-radius: 10px;
    font-weight: 600;
    transition: 0.3s;
    z-index: 1;
}
#refinement_list_container #refinement_list__ .brand_name{
    color: var(--text-color);
    font-size: 14px;
    text-decoration: underline;
    text-decoration-color: var(--white-color);
    white-space: nowrap; 
    width: 50px; 
    overflow: hidden;
    text-overflow: ellipsis;
}
#refinement_list_container #refinement_list__ .img_sec img {
    max-width: 100%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: center;
    padding: 0px;
}
#refinement_list_container #refinement_list__ .img_sec span{
    display: unset !important;
}