.output_container .search_container form{
    display: flex;
    justify-content: center;
  }
  .output_container .search_container form input{ 
    width: 60%;
    padding: 10px;
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    border: 0;
    outline: none;
    border-radius: 5px;
  }
  .output_container .search_container form input:focus-visible{ 
    border: 0;
    outline: none;
  }
  /* .output_container .search_container form .search_box{ 
    background-color: rgb(60, 39, 13);
  } */
  #search__ form{
    position: relative;
  }
  #search__ form .search_box{
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
  }