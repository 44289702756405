#refinement_sc_container #refinement_sc__ .form-group {
    display: block;
    margin-bottom: 15px;
}

#refinement_sc_container #refinement_sc__ .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

#refinement_sc_container #refinement_sc__ .form-group label {
    position: relative;
    cursor: pointer;
}
#refinement_sc_container #refinement_sc__ .form-group:hover .count{
    background-color: rgb(108 93 211 / 70%);
    color: white;
    text-decoration-color: rgb(152 141 224);
}

.select{
    background-color: rgb(108 93 211 / 70%) !important;
    color: white !important;
    text-decoration-color: rgb(152 141 224) !important; 
}

#refinement_sc_container #refinement_sc__ .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    /* border: 2px solid var(--primary-color); */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 7px;
    box-shadow: 0 0 0 0.2rem rgba(108,93,211,0.3);
}
#refinement_sc_container #refinement_sc__ .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 6px;
    height: 12px;
    border: solid var(--white-color);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
#refinement_sc_container #refinement_sc__ .form-group input:checked + label:before{
    background-color: var(--primary-color);
}
#refinement_sc_container #refinement_sc__ label{
    color: var(--text-color);
}
#refinement_sc_container #refinement_sc__ .bottom_section span.count{
    color: var(--text-color);
    text-decoration: underline;
    text-decoration-color: var(--white-color);
    background-color: rgb(108 93 211 / 10%);
    padding: 4px 9px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
    transition: 0.3s;
}