#range_slider .DefaultHandle_handle {
    background-color: var(--white-color);
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    width: 20px;
    height: 20px;
    /* transition: .1s; */
}
#range_slider .DefaultHandle_handle:hover{
    background-color: var(--primary-color);
}
#range_slider .DefaultProgressBar_progressBar{
    background-color: var(--slider-bg);
}
#range_slider .DefaultHandle_handle__horizontal {
    top: -3px;
    cursor: grab;
}
#range_slider .DefaultHandle_handle__horizontal:focus-visible {
    cursor: grab;
}
#range_slider .DefaultBackground_background__horizontal {
    height: 8px;
    top: 1px;
    border-radius: 10px;
}
#range_slider .DefaultProgressBar_background__horizontal {
    height: 7.5px;
    top: 1px;
}
#range_slider .DefaultHandle_handle:before{
    background-color: unset;
}
#range_slider .DefaultHandle_handle:after{
    background-color: unset;
}
#range_slider .rheostat-marker.rheostat-marker--large{
    position: absolute;
    top: 18px;
}
#range_slider .rheostat-value{
    font-size: 12px;
    color: var(--text-color);
}




/* body .rheostat-handle
{
    border: 2px solid var(--primary-color);
    cursor:move;
}

body .rheostat-handle:hover{ background: var(--primary-color);}

body .ais-RangeSlider .rheostat-progress
{
    background-color: #dee4ec
}

body .rheostat-value{ font-size:10px;}

body  .rheostat-tooltip
{
    font-size: 10px;
    line-height: 1;
    text-shadow: none;
    padding: 4px 8px;
    background-color: rgba(var(--bs-primary-rgb), 0.8);
    color: #fff;
    border-radius: 6px;
    top: -25px;
    position: absolute;
    display: block;
    cursor: default;
    white-space: nowrap;

}

body  .rheostat-tooltip::before
{
position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: rgba(var(--bs-primary-rgb), 0.8);

}


.ais-RangeSlider .rheostat{
    overflow:visible;
    margin-top:40px;
    margin-bottom:40px
}
.ais-RangeSlider .rheostat-background{
    height:6px;
    top:0;
    width:100%
}
.ais-RangeSlider .rheostat-handle{
    margin-left:-12px;
    top:-7px
}
.ais-RangeSlider .rheostat-background{
    position:relative;
    background-color:#fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}
.ais-RangeSlider .rheostat-progress{
    position:absolute;
    top:1px;
    height:4px;
    background-color:#333
}
.rheostat-handle{
    position:relative;
    z-index:1;
    width:20px;
    height:20px;
    background-color:#fff;
    border:1px solid #333;
    border-radius:50%;
    cursor:-webkit-grab;
    cursor:grab
}
.rheostat-marker{
    margin-left:-1px;
    position:absolute;
    width:1px;
    height:5px;
    background-color:#aaa
}
.rheostat-marker--large{
    height:9px
}
.rheostat-value{
    margin-left:50%;
    padding-top:15px;
    position:absolute;
    text-align:center;
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%)
}
.rheostat-tooltip{
    margin-left:50%;
    position:absolute;
    top:-22px;
    text-align:center;
    -webkit-transform:translateX(-50%);
    transform:translateX(-50%)
} */




/*  range slider  */