 #loader .loader {
    display: flex;
  }
  
  #loader .loader__item {
    width: 16px;
    height: 16px;
    margin: 4px;
    background: var(--primary-color);
    border-radius: 50%;
    list-style: none;
    -webkit-animation: animation-loading 1.2s infinite;
            animation: animation-loading 1.2s infinite;
  }
  #loader .loader__item:nth-child(1) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  #loader .loader__item:nth-child(2) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  
  @-webkit-keyframes animation-loading {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    80% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes animation-loading {
    0% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    80% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }